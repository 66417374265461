.title h2 {
  font-size: 47px;
  margin-bottom: 0;
}

.title h3 {
  margin-bottom: 0;
  margin-top: -10px;
  font-size: 60px;
  font-weight: 100;
}

.title p {
  line-height: 1.3em;
  margin: auto;
  margin-top: 50px;
  max-width: 600px;
}
