section#Noper {
    display: flex;
    position: relative;
    height: 800px;
    background-color: #009ecc;
    background-size: 600px;
    background-size: 14vw;
    background-size: calc(200px + 4vw);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 52% 50%, 38% 30%;
}

section#Noper .card {
    top: 200px;
    left: 50%;
    width: 45%;
}

@media only screen and (max-width: 1023px) {

    section#Noper {
        height: 900px;
        background-position: 80% 20%, 25% 11%;
        background-size: 160px;
        background-attachment: scroll;
    }

    section#Noper .card {
        top: 340px;
        left: 10px;
        right: 10px;
        width: auto;
    }

}