section#Projects {
    padding: 50px 70px 120px;
    background-color: #1c4b68;
    text-align: center;
    color: #fff;
}

section#Projects .projects-container {
    display: flex;
    margin-top: 180px;
}

section#Projects .projects-project-item {
    position: relative;
    flex-grow: 1;
    padding: 0 50px;
    width: 50%;
}

section#Projects .projects-project-item img {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
}

section#Projects .projects-project-item h3 {
    font-size: 30px;
    font-weight: 200;
}

section#Projects .projects-project-item p {
    margin-bottom: 30px;
}

section#Projects .projects-project-item a {
    position: absolute;
    left: 50%;
    bottom: -30px;
    padding: 6px 16px;
    transform: translateX(-50%);
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: #ffffff;
}

section#Projects .projects-project-item a:hover {
    background-color: #ffffff;
    color: #1c4b68;
}

section#Projects .projects-project-1 {
}

section#Projects .projects-project-2 {
}

@media only screen and ( max-width: 1023px ) {

    section#Projects .projects-container {
        margin-top: 150px;
        flex-direction: column;
    }

    section#Projects .projects-project-item {
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }

    section#Projects .projects-project-item + .projects-project-item {
        margin-top: 200px;
    }

}