section#ShowHi {
    display: flex;
    position: relative;
    height: 740px;
    background-color: #e04747;
    background-size: 600px;
    background-size: 40vw;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 45% 50%;
}

section#ShowHi .card {
    top: 230px;
    left: 50%;
    right: 10%;
}

section#ShowHi .card h2 {
    padding-left: 28px;
}

section#ShowHi .card img {
    margin-top: 3px;
}

@media only screen and ( max-width: 1023px ) {

    section#ShowHi {
        height: 750px;
        background-position: 50% 70%;
        background-size: 350px;
        background-attachment: scroll;
    }

    section#ShowHi .card {
        top: 260px;
        left: 10px;
        right: 10px;
        width: auto;
    }

}