#Hello {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #c57165;
}

#Hello h2 {
    position: absolute;
    top: 270px;
    left: 15%;
    font-size: 57px;
    font-weight: 200;
}

#Hello h3 {
    position: absolute;
    top: 390px;
    left: 15%;
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
}

#Hello h3 p {
    margin: 0;
}

@media only screen and ( max-width: 1360px ) {

    #Hello h2 {
        top: 230px;
    }

    #Hello h3 {
        top: 350px;
    }

}

@media only screen and ( max-width: 1023px ) {

    #Hello {
        position: absolute;
    }

    #Hello h2 {
        top: 80px;
    }

    #Hello h3 {
        top: 170px;
    }

}