section#Intro {
    display: flex;
    padding-left: 15vw;
    height: 750px;
    flex-direction: row;
}

section#Intro .intro-shortcuts {
    display: flex;
    position: relative;
    margin-top: 30px;
    margin-left: 20%;
    width: 100%;
}

section#Intro .intro-shortcuts::before {
    position: absolute;
    top: 250px;
    left: -70px;
    height: 200px;
    border-left: 1px solid #ffffff;
    content: "";
}

@media only screen and ( max-width: 1360px ) {

    section#Intro {
        height: 600px;
    }

}

@media only screen and ( max-width: 1023px ) {

    section#Intro {
        position: relative;
        padding-left: 0;
        height: 550px;
    }

    section#Intro .intro-shortcuts {
        display: initial;
        position: absolute;
        bottom: 0;
        margin: 0;
        padding-bottom: 70px;
        padding-left: 60px;
        padding-right: 30px;
        overflow-x: scroll;
        white-space: nowrap;
        vertical-align: top;
    }

}