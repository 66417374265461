.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
}

#Navigator .social {
    animation: nav-items-visible 300ms;
    flex-direction: row;
    opacity: 1;
    position: fixed;
    right: 30px;
    top: 24px;
    visibility: initial;
}

#Navigator.isbottom .social {
    visibility: hidden;
    animation: nav-items-hidden 300ms;
    opacity: 0;
}

.social a + a {
    margin-left: 10px;
}

.social a {
    width: 24px;
    height: 24px;
    border-radius: 20px;
}

.social a svg path {
    fill: #dddddd;
    transition: 300ms fill;
}

#Navigator .social a svg path {
    fill: #aaaaaa;
    transition: 300ms fill;
}

.social a:hover svg path {
    fill: #ffffff;
}

#Navigator .social a:hover svg path {
    fill: #777777;
}

@media only screen and (min-width: 768px) {
    #Navigator .social {
        bottom: 70px;
        left: 140px;
        right: auto;
        top: auto;
    }
}
