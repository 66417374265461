footer#Contact {
    display: flex;
    padding-top: 100px;
    padding-bottom: 80px;
    background-color: #3a3a3a;
    color: #ffffff;
    text-align: center;
    flex-direction: column;
}

footer#Contact h3 {
    margin-top: 150px;
    font-size: 15px;
    font-weight: 300;
    color: #777777;
}

@media only screen and ( max-width: 1023px ) {

    footer#Contact {
        padding-top: 50px;
        padding-bottom: 30px;
    }

    footer#Contact h2 {
        margin-bottom: 30px;
    }

    footer#Contact h3 {
        margin-top: 80px;
    }

}