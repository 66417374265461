.card {
  position: absolute;
  padding: 30px 30px 40px;
  background-color: #fff;
}

.card h2 {
  display: flex;
  height: 30px;
  font-size: 30px;
  font-weight: 200;
  line-height: 30px;
  padding-left: 42px;
  position: relative;
  margin-top: 0;
}

.card img {
  position: absolute;
  left: 0;
}

.card .card-specialities span {
  display: inline-block;
  margin-right: 7px;
  padding: 2px 10px 3px;
  border-radius: 10px;
  font-size: 10px;
  line-height: 10px;

  margin-top: -3px;
}

.card .card-specialities span {
  color: #ffffff;
}

.card .card-explain {
  font-size: 14px;
  margin-top: 30px;
  white-space: pre-wrap;
}

.card > a {
  position: absolute;
  bottom: -20px;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 36px 12px;
  font-size: 14px;
  background-color: #333;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 300ms background-color;
}

.card > a:hover {
  background-color: #777;
}

@media only screen and (max-width: 1023px) {
  .card {
    top: 340px;
    left: 10px;
    right: 10px;
    width: auto;
  }

  .cards p {
    margin-top: 30px;
  }

  .cadd > a {
    left: 50%;
    transform: translateX(-50%);
  }
}
