#Logo {
    position: fixed;
    top: 130px;
    left: 140px;
    z-index: 9;
    color: #ffffff;
    transition: 300ms color;
}

#Logo.black {
    color: #000;
}

#Logo > img,
#Logo > svg {
    width: 70px;
}

#Logo > svg path {
    transition: 300ms stroke;
}

#Logo.black > svg path {
    stroke: #000;
}

#Logo > span {
    position: absolute;
}

#Logo > span:first-of-type {
    top: 36px;
    left: 65px;
    font-size: 15px;
    font-weight: bold;
}

#Logo > span:last-of-type {
    top: 55px;
    left: 57px;
    font-size: 12px;
    font-weight: lighter;
}

@media only screen and ( max-width: 1023px ) {

    #Logo {
        position: absolute;
        top: 50px;
        left: 36px;
        transform-origin: top;
        transform: scale(0.4);
    }

    #Logo.black {
        position: fixed;
        top: 22px;
    }

    #Logo > span:first-of-type {
        margin-top: -7px;
        transform-origin: top left;
        transform: scale(1.5);
    }

    #Logo > span:last-of-type {
        margin-left: -3px;
        transform-origin: top left;
        transform: scale(1.5);
    }

}