#App {
    position: relative;
}

#App section:not(#Hello),
#App footer {
    margin-left: 300px;
    margin-right: 30px;
}

@media only screen and ( max-width: 1023px ) {

    #App section:not(#Hello),
    #App footer {
        margin-left: 0;
        margin-right: 0;
    }

}