#Navigator {
    position: absolute;
    top: 750px;
    bottom: 0;
    width: 300px;
    background-color: #ffffff;
    z-index: 6;
}

#Navigator nav {
    position: fixed;
    bottom: 150px;
    left: 100px;
    width: 200px;
    animation: nav-items-hidden 300ms;
    visibility: hidden;
    opacity: 0;
}

#Navigator.navmode nav {
    animation: nav-items-visible 300ms;
    visibility: visible;
    opacity: 1;
}

#Navigator nav a {
    display: block;
    padding: 10px;
    padding-right: 70px;
    width: 100%;
    text-align: right;
    font-size: 20px;
    font-weight: 300;
    transition-duration: 300ms;
    transition-property: color;
    color: #555555;
}

#Navigator nav a:hover {
    color: #111111;
}

#Navigator nav a img {
    height: 20px;
    margin-bottom: -2px;
    margin-left: 6px;
}

@keyframes nav-items-visible {
    from {
        visibility: hidden;
        opacity: 0;
    }
    to {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes nav-items-hidden {
    from {
        visibility: visible;
        opacity: 1;
    }
    to {
        visibility: hidden;
        opacity: 0;
    }
}

@media only screen and ( max-width: 1360px ) {

    #Navigator {
        top: 600px;
    }

}

@media only screen and ( max-width: 1023px ) {

    #Navigator {
        position: fixed;
        top: 0;
        bottom: auto;
        left: 0;
        right: 0;
        z-index: 7;
        width: auto;
        height: 70px;
        transition-duration: 300ms;
        transition-property: background-color box-shadow;
        background-color: rgba(256, 256, 256, 0);
    }

    #Navigator.navmode {
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    #Navigator nav {
        display: none;
    }

    #Navigator p {
        display: none;
        /*position: absolute;*/
        /*top: 400px;*/
        /*left: 125px;*/
    }

}