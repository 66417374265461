section#EnglishAgency {
    display: flex;
    position: relative;
    height: 730px;
    background-color: #f3f3f3;
    background-size: 600px;
    background-size: 10vw, 35vw;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 93% 50%, 93% 50%;
}

section#EnglishAgency .card {
    top: 180px;
    width: 40%;
    right: 50%;
}

@media only screen and ( max-width: 1023px ) {

    section#EnglishAgency {
        height: 800px;
        background-position: 92% 15%, 30% 11%;
        background-size: 100px ,300px;
        background-attachment: scroll;
    }

    section#EnglishAgency .card {
        top: 240px;
        left: 10px;
        right: 10px;
        width: auto;
    }
}