section#About {
    position: relative;
    padding-top: 60px;
    padding-bottom: 100px;
    min-height: 800px;
    background-color: #f3f3f3;
    text-align: center;
}

section#About .about-profile {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

section#About .about-bio {
    margin: auto;
    max-width: 520px;
    text-align: left;
    text-align: justify;
    white-space: pre-wrap;
}

section#About .about-avatar {
    margin-right: 100px;
    margin-bottom: 80px;
    width: 220px;
    height: 220px;
    background-position: 55% 36%;
    background-size: 120%;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 1023px) {
    section#About {
        padding-top: 60px;
        min-height: 600px;
    }

    section#About h3 {
        font-size: 32px;
    }

    section#About .about-profile {
        flex-direction: column;
    }

    section#About .about-avatar,
    section#About .skills {
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    section#About .about-bio {
        padding-left: 60px;
        padding-right: 60px;
        font-size: 13px;
    }
}
