section#ShibaTv {
    display: flex;
    position: relative;
    height: 670px;
    background-color: #c59c70;
    background-size: 600px;
    background-size: 30vw, 30vw;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 82% 50%, 88% 50%;
}

section#ShibaTv .card {
    top: 170px;
    right: 50%;
    left: 10%;
}

section#ShibaTv .card img {
    margin-top: 3px;
}

@media only screen and ( max-width: 1023px ) {

    section#ShibaTv {
        height: 700px;
        background-position: 35%, 70%;
        background-size: 280px;
        background-attachment: scroll;
    }

    section#ShibaTv .card {
        top: 260px;
        left: 10px;
        right: 10px;
        width: auto;
    }

}